import React, { Component } from "react";
import "./style.scss";
import logoSm from '../../../assets/images/logo-sm.png';
import logoFull from '../../../assets/images/logo-full.png'
import micIcon from '../../../assets/icons/mic-2-fill.png'
import { Col, Row } from "reactstrap";
import { cancelToken } from "../../../services/adapters/base";
import apiResponse from "../../../services/apiResponse";
import { GlobalContext } from "../../../contexts/GlobalContext";
import AgendaBloc from "./Blocs/AgendaBloc";
import PortalServiceBloc from "./Blocs/PortalServiceBloc";
import HelpdeskBloc from "./Blocs/HelpdeskBloc";
import AgendaSection from "./Components/AgendaSection";
import AnnouncementPopup from "./Components/AnnouncementPopup";
import HelpDesk from "./Components/HelpDesk";
import AppPopup from "./Components/AppPopup";
import FooterSection from "./Components/Footer";
import HeroSection from "./Components/HeroSection";
import PanduanSection from "./Components/PanduanSection";
import PengumumanSection from "./Components/PengumumanSection";
import MinioClient from "../../../services/minioClient";
import PortalSection from "./Components/PortalSection";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Profile from "./Components/Profile/Profile";
import SearchView from "./Components/Search/Search";
import FuzzySet from 'fuzzyset';
import * as tf from "@tensorflow/tfjs";
import { Stemmer } from 'sastrawijs';
import SearchInput from "./Components/SearchInput";
import EmployeeBloc from "./Blocs/EmployeeBloc";
import { ConfirmAlert } from "../../../helpers/ui/alert";
class IndexView extends Component {

  static contextType = GlobalContext;
  source;
  employeeBloc = new EmployeeBloc
  agendaBloc = new AgendaBloc();
  portalServiceBloc = new PortalServiceBloc();
  helpdeskBloc = new HelpdeskBloc();
  minioClient = new MinioClient();
  userData = JSON.parse(localStorage.getItem("userdata"));
  stemmer = new Stemmer();

  constructor(props) {
    super(props);
    this.state = {
      dataForm: {},
      portalServices: null,
      portalServicesIcon: [],
      showAppModal: false,
      showAgendaModal: false,
      modalContent: null,
      showServices: false,
      listening: false,
      keywords: [],
      filter: sessionStorage.getItem(this.historyTable)
        ? JSON.parse(sessionStorage.getItem(this.historyTable))
        : {
          filter_value: "",
          page_number: 1,
          page_size: this.defaultSize,
          sort_order: {
            column: this.defaultOrder,
            order: this.defaultSort,
          },
        },
        loadedModel: null,
        inputSentence: '',
        referenceWord: [],
        candidates: [], // Initialize candidates
        indonesianWords: null,
        fuzzy: null,
        predictedBestCandidate: '',
        predictedProbability: 0,
        suggestion: null,
        roughs: [],
        roughCandidates: [],
        roughCallback: null,
        showRough: false
    };
  }

  toggleService = () => {
    this.setState({
      showServices: !this.state.showServices
    })
  }

  submitData = async () => {
    const { dataForm } = this.context;
    await this.agendaBloc.fetchCreate(dataForm);
  };

  handlerOnSubmit = (e, values) => {
    const { dataForm, setDataForm } = this.context;

    e.preventDefault();
    setDataForm({
      ...dataForm,
      ...values,
    });
    this.setState({
      confirm: true,
    });
  };

  preventEnter = (e) => {
    if (e.which === 13) {
      e.preventDefault();
    }
  };

  confirmResponse = (response) => {
    let forState = {};
    switch (response) {
      case "cancel":
        forState = {
          confirm: false,
        };
        break;
      case "confirm":
        forState = {
          loading: true,
          confirm: false,
        };
        this.submitData();
        break;
      case "success":
        forState = {
          success: false,
          redirect: true,
        };
        break;
      case "failed":
        forState = {
          failed: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  setTokenAPI = () => {
    if (typeof this.source != typeof undefined) {
      this.source.cancel();
    }
    this.source = cancelToken();
    this.loadData();
  };

  loadData = async () => {
    /* kirim ke server */
    const query = {
      search: null,
      limit: this.state.filter.page_size,
      offset: null,
      order: this.state.filter.sort_order.column,
      sort: this.state.filter.sort_order.order,
      nip: this.userData.employee_nip,
      status: 1,
    };

    const queryPortalService = {
      search: null,
      limit: 99,
      offset: null,
      order: "portal_service_id",
      sort: "asc",
    };

    const queryHelpdeskService = {
      search: null,
      limit: this.state.filter.page_size,
      offset: null,
      order: "helpdesk_service_id",
      sort: "asc",
    };

    const queryKeywordService = {
      search: null,
      limit: this.state.filter.page_size,
      offset: null,
      order: "nlp_keyword_id",
      sort: "asc",
    };

    const queryRoughService = {
      search: null,
      limit: this.state.filter.page_size,
      offset: null,
      order: "rough_id",
      sort: "asc",
    };

    await this.portalServiceBloc.fetchList(queryPortalService, this.source.token);
    await this.portalServiceBloc.fetchKeyword(queryKeywordService, this.source.token);
    await this.portalServiceBloc.fetchRoughs(queryRoughService, this.source.token);
    await this.agendaBloc.fetchList(query, this.source.token);
    await this.helpdeskBloc.fetchList(queryHelpdeskService, this.source.token);
  };

  greeting = () => {
    const speech = new SpeechSynthesisUtterance("Selamat datang di aplikasi jarpis"); 
    speech.lang = 'id';
    window.speechSynthesis.speak(speech);
  }

  componentDidMount() {
    const { setAgendas } = this.context;
    
    // this.greeting();

    const loadModel = async () => {
      const modelPath = '/model.json';
      const model = await tf.loadLayersModel(modelPath);
      this.setState({
        loadedModel: model
      });
    };
    loadModel();

    this.setTokenAPI();

    this.agendaBloc.listChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.INITIAL:
          this.setState({
            loadingTable: false,
          });
          break;
        case apiResponse.LOADING:
          this.setState({
            loadingTable: true,
          });
          break;
        case apiResponse.COMPLETED:
          const response = result.data.response;

          this.setState({
            agendas: response.result,
            loadingTable: false,
          });

          setAgendas(response.result);

          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });

    this.portalServiceBloc.listChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          const response = result.data.response;
          this.setState({
            portalServices: response.result,
            loadingTable: false,
          });

          response.result.map((service, index) => {
            this.setServiceIcon(service, index)
          })

          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });

    this.portalServiceBloc.keywordChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          const response = result.data.response;
          const keywords = response.result;
          const candidates = keywords.map((item) => item.nlp_keyword_value.split(" "))
          this.setState({
            keywords: keywords,
            candidates: candidates,
          });

          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });

    this.portalServiceBloc.roughChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          const response = result.data.response;
          const roughs = response.result;
          const candidates = roughs.map((item) => item.rough_value.split(" ").map(subItem => subItem.toLowerCase()))
          const indonesianWords = [].concat(...candidates)
          const fuzzy = FuzzySet(indonesianWords)
          this.setState({
            roughs: roughs,
            roughCandidates: candidates,
            indonesianWords: indonesianWords,
            fuzzy: fuzzy
          });

          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });

    this.helpdeskBloc.listChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          const response = result.data.response;
          this.setState({
            helpdeskServices: response.result,
            loadingTable: false,
          });

          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { setAgendas } = this.context;
    setAgendas(this.state.agendas);
  }

  componentWillUnmount() {
    this.agendaBloc.listChannel.unsubscribe();
    this.portalServiceBloc.listChannel.unsubscribe();
    this.helpdeskBloc.listChannel.unsubscribe();
    this.portalServiceBloc.keywordChannel.unsubscribe();
    this.portalServiceBloc.roughChannel.unsubscribe();
    tf.dispose()
  }

  setServiceIcon = (service, index) => {
    this.minioClient.get(service.attachment_path).then((url) => {
      this.setState({
        portalServicesIcon: [...this.state.portalServicesIcon, {[service.portal_service_id]: url}]
      });
    });
  }

  toggleModalAgenda = () => {
    this.setState({
      showModalAgenda: !this.state.showAgendaModal,
    });
  };

  toggleAppModal = (id) => {
    if (id) this.setModalContent(id);

    this.setState({
      showAppModal: !this.state.showAppModal,
    });
  };

  setModalContent = (id) => {
    this.setState({
      modalContent: this.state.portalServices.filter((item) => item.portal_service_id === id)[0],
    });
  };

  handleChangeListening = () => {
    this.setState({listening: !this.state.listening});
  }

  fetchSuggestions = (word) => {
    if (word != null) {
      const fuzzyResults = this.state.fuzzy.get(word); // Make sure 'fuzzy' is accessible
      const autocorrectSuggestions = fuzzyResults ? (fuzzyResults[0][0] > 0.7 ? fuzzyResults[0][1] : word) : word;
      return autocorrectSuggestions;
    }
  };

  handlePredict = () => {
    const { loadedModel, inputSentence, candidates } = this.state;

    if (!loadedModel) return;

    const reference = this.stemmer.stem(inputSentence)
      .split(' ').map((word) => this.fetchSuggestions(word));

    this.setState({ referenceWord: reference });

    const bleuScores = candidates.map(candidate => {
      const commonWords = candidate.filter(word => reference.includes(word)).length;
      return commonWords;
    });

    const maxBleuScore = Math.max(...bleuScores);
    const normalizedBleuScores = maxBleuScore === 0 ? new Array(candidates.length).fill(0) : bleuScores.map(score => score / maxBleuScore);

    // Predict probabilities using the loaded model
    const tensorInput = tf.tensor(normalizedBleuScores);
    const predictedProbabilityTensor = loadedModel.predict(tensorInput);

    const predictedProbability = predictedProbabilityTensor.dataSync()[0];
    const predictedBestCandidateIndex = predictedProbability > 0.5
      ? normalizedBleuScores.indexOf(Math.max(...normalizedBleuScores))
      : -1;

    const predictedBestCandidate = predictedBestCandidateIndex !== -1
      ? candidates[predictedBestCandidateIndex]
      : "None";

    this.setState({
      predictedBestCandidate: predictedBestCandidate.join(" "),
      predictedProbability: predictedProbability,
    });

    this.processSelectedValue(predictedBestCandidate.join(" "));
  };

  processSelectedValue = (data) => {
    const bestData = this.state.roughs.find((item) => item.rough_value.toLowerCase() === data.toLowerCase());
    console.log(bestData)
    if (bestData != null) {
      this.setState({
        roughCallback: bestData.rough_callback,
        showRough: true
      })
    }
  };

  handleInputChange = (event) => {
    const newValue = event.target.value;
    this.setState({ inputSentence: newValue });
    if (newValue != "") {
      this.handlePredict(); 
    } else {
      this.setState({
        suggestion: null
      })
    }
  };

  finishTour = () => {
    this.employeeBloc.fetchFinishTour()
  }

  findRough = (keyword) => {    
    const reference = this.stemmer.stem(keyword).split(' ').map((word) => this.fetchSuggestions(word));
    const bleuScores = this.state.roughCandidates.map(candidate => {
      const candidateString = candidate.join(" ");
      const candidateTokens = candidateString.split(' ');
      const precision = this.calculateUnigramPrecision(reference, candidateTokens);
      const brevityPenalty = this.calculateBrevityPenalty(reference.length, candidateTokens.length);
      const bleu = precision * brevityPenalty;
      return bleu;
    });

    const maxBleuScore = Math.max(...bleuScores);
    
    if (maxBleuScore > 0) {
      
      const normalizedBleuScores = maxBleuScore === 0 ? new Array(this.state.roughCandidates.length).fill(0) : bleuScores.map(score => score / maxBleuScore);
  
      // Predict probabilities using the loaded model
      const tensorInput = tf.tensor(normalizedBleuScores);
      const predictedProbabilityTensor = this.state.loadedModel.predict(tensorInput);
  
      const predictedProbability = predictedProbabilityTensor.dataSync()[0];
      const predictedBestCandidateIndex = predictedProbability > 0.5
        ? normalizedBleuScores.indexOf(Math.max(...normalizedBleuScores))
        : -1;
  
      const predictedBestCandidate = predictedBestCandidateIndex !== -1
        ? this.state.roughCandidates[predictedBestCandidateIndex]
        : "None";
  
      this.setState({
        predictedBestCandidate: predictedBestCandidate,
        predictedProbability: predictedProbability,
      });
  
      this.processSelectedValue(predictedBestCandidate.join(" "));
    }
  }

  // Calculate unigram (1-gram) precision
  calculateUnigramPrecision = (referenceTokens, candidateTokens) => {
    const commonUnigrams = candidateTokens.filter(token => referenceTokens.includes(token));
    const precision = commonUnigrams.length / candidateTokens.length;
    return precision;
  }

  // Calculate brevity penalty
  calculateBrevityPenalty = (referenceLength, candidateLength) => {
    if (candidateLength >= referenceLength) {
      return 1;
    } else {
      return Math.exp(1 - referenceLength / candidateLength);
    }
  }

  render() {
  //   return (
  //     <>
  //      {this.state.listening == false ? (
  //       <div
  //         className="main"
  //         style={{
  //           position: "relative",
  //           minHeight: "100vh",
  //         }}
  //       >
  //         <img
  //           src={logoSm}
  //           alt="ornaments"
  //           style={{
  //             position: "fixed",
  //             top: "90vh",
  //             left: "-40rem",
  //             transform: "translate(50%, -50%)",
  //             width: "40rem",
  //             userSelect: "none",
  //             MozUserSelect: "none",
  //             opacity: 0.2,
  //             filter: 'blur(5px)'
  //           }}
  //         />

  //         <Profile/>

  //         <div className="centered" style={{ minHeight: "100vh", paddingTop: "5rem", paddingBottom: "5rem", paddingLeft: "2rem", paddingRight: "2rem"}}>
  //           <Col>
  //             <Row className="centered-horizontal">
  //               <img src={logoFull} style={{ width: "15rem", marginBottom: "5rem"}} />  
  //             </Row>

  //             {this.state.keywords.length > 0 && (
  //               <Row className="centered-horizontal" style={{ position: "relative", zIndex: 99 }} >
  //                 <div className="pt-2" style={{width: "643px", height: "50px", backgroundColor: "#F3F2F2", border: "none", outline: "none", borderRadius: "60px", marginBottom: "3rem", paddingLeft: "20px", paddingRight: "20px" }}>
  //                   <input
  //                     type="text"
  //                     style={{fontSize: "18px", width: "100%", backgroundColor: "#F3F2F2", border: "none", outline: "none", borderRadius: "60px", marginBottom: "2rem", paddingLeft: "20px", paddingRight: "20px"}}
  //                     onChange={this.handleInputChange}
  //                   />
  //                   {this.state.suggestion != null && (
  //                     <div style={{ padding: 24, borderRadius: "8px", border: "1px solid #DEDEDE", background: "linear-gradient(80deg, #F3F2F2 -180.51%, #FFF 126.12%)" }}>
  //                       <p style={{ color: "var(--Default-Grey, #575757)", fontFamily: "IBM Plex Sans", fontSize: "12px", fontStyle: "normal", fontWeight: 400, lineHeight: "normal" }}>Suggested Search</p>
  //                       <p style={{ color: "var(--Default-Grey, #575757)", fontFamily: "IBM Plex Sans", fontSize: "16px", fontStyle: "normal", fontWeight: 500, lineHeight: "normal" }}>{this.state.suggestion}</p>
  //                     </div>
  //                   )}
  //                 </div>
  //                   <div onClick={this.changeListening} className="centered" style={{width: "50px", height: "50px", backgroundColor: "#2D3A90", borderRadius: "60px", marginLeft:"-50px"}}>
  //                     <img src={micIcon} style={{ height: "1.5rem" }} />
  //                   </div>
  //               </Row>
  //             )}
  //             <Row className="centered-horizontal">
  //               <div className="centered-horizontal" onClick={this.toggleService}>
  //                 <div style={{ alignItems: "center", marginRight: "10px", cursor: "default"}}>{this.state.showServices == true ? "Hide Apps" : "Show Apps"}</div>
  //                 <div style={{ alignItems: "center" }}>{this.state.showServices == true ? <i className="fa fa-chevron-up"></i> : <i className="fa fa-chevron-down"></i> }</div>
  //               </div>
  //             </Row>

  //             {(this.state.portalServices && this.state.portalServicesIcon.length === this.state.portalServices.length && this.state.showServices == true) && (
  //               <div style={{ paddingLeft: "6rem", paddingRight: "6rem" }}>
  //                 <PortalSection
  //                   applications={this.state.portalServices}
  //                   icons={this.state.portalServicesIcon}
  //                   toggleAppModal={this.toggleAppModal}
  //                 />
  //               </div>
  //             )}

          
  //           {(this.state.helpdeskServices) && (
  //             <>
  //               <HelpDesk 
  //                 services={this.state.helpdeskServices}
  //               />
  //             </>
  //           )}

  //           <AppPopup
  //             show={this.state.showAppModal}
  //             toggle={this.toggleAppModal}
  //             application={this.state.modalContent}
  //           />
  //           </Col>
  //         </div>
  //       </div>
  //      ) : <SearchView keywords={this.state.keywords} changeListening={this.changeListening}/>}
  //     </>
  //   );

    return (
      <>
        {this.state.listening == false ? (
          <div style={{ overflowX: "hidden" }}>
            {/* {localStorage.getItem('hidePopup') != "true" && ( */}
              <AnnouncementPopup />
            {/* )} */}
            <div className="main">
              <Profile/>
              <HeroSection />
              {this.state.keywords.length > 0 && (
                <SearchInput keywords={this.state.keywords} searchRough={this.findRough} changeListening={this.handleChangeListening} endTour={this.finishTour}/>
              )}
              {(this.state.portalServices && this.state.portalServicesIcon.length === this.state.portalServices.length) && (
                <>
                  <PortalSection
                    applications={this.state.portalServices}
                    icons={this.state.portalServicesIcon}
                    toggleAppModal={this.toggleAppModal}
                  />
                </>
              )}
              <div className="row announcement-content" style={{ backgroundColor: "#F8F9FC"}}>
                <div className="col-md-6">
                  <>
                    <PengumumanSection />
                  </>
                </div>
                <div className="col-md-6">
                  {this.state.agendas && (
                    <>
                      <AgendaSection
                        agendas={this.state.agendas}
                        token={this.setTokenAPI}
                      />
                    </>
                  )}
                </div>

              </div>
              {/* <>
                <PanduanSection />
              </> */}
              
              {(this.state.helpdeskServices) && (
                <>
                  <HelpDesk 
                    services={this.state.helpdeskServices}
                  />
                </>
              )}

              <AppPopup
                show={this.state.showAppModal}
                toggle={this.toggleAppModal}
                application={this.state.modalContent}
              />
            </div>
            <FooterSection noSubscription={true} />
          </div>
        ) : <SearchView keywords={this.state.keywords}  searchRough={this.findRough} changeListening={this.handleChangeListening}/>}

        <ConfirmAlert
          warningTitle="Perhatian!"
          warningInfo={this.state.roughCallback}
          showWarning={this.state.showRough}
          response={(response) => {
            this.setState({
              showRough: false,
              roughCallback: null
            })
          }}
        />
      </>
    )
  }
}

export default IndexView;
