import { Icon } from "@iconify/react";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import { Component } from "react";
import { Modal, ModalBody } from "reactstrap";
import apiResponse from "../../../../../services/apiResponse";
import MinioClient from "../../../../../services/minioClient";
import { cancelToken } from "../../../../../services/adapters/base";
import AnnouncementBloc from "../../Blocs/AnnouncementBloc";
import { Editor, EditorState, convertFromRaw } from "draft-js";
import "./style.scss";


class AnnouncementPopup extends Component {

  source;
  announcementBloc = new AnnouncementBloc();
  minio = new MinioClient();

  constructor(props) {
    super(props);
    this.state = {
      announcement: null,
      showModal: true,
    };
  }

  setTokenAPI = () => {
    if (typeof this.source != typeof undefined) {
      this.source.cancel();
    }
    this.source = cancelToken();
    this.loadData();
  };

  loadData = async () => {
    /* kirim ke server */
    const query = {
    };
    await this.announcementBloc.fetchPopupAnnouncement(query, this.source.token);
  };

  fetchAttachment = async (path) => {
    const attachment = await this.minio.get(path).then(
      (res) => res,
      (err) => err
    );

    this.setState({
      announcement: {
        ...this.state.announcement,
        attachment_uri: attachment,
      },
    });
  };

  componentDidMount() {
    this.setTokenAPI();

    this.announcementBloc.popupChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.INITIAL:
          this.setState({
            loadingTable: false,
          });
          break;
        case apiResponse.LOADING:
          this.setState({
            loadingTable: true,
          });
          break;
        case apiResponse.COMPLETED:
          const response = result.data.response;

          this.setState({
            announcement: response.result,
            loadingTable: false,
          });

          this.fetchAttachment(response.result.attachment_path);

          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });
  }

  componentWillUnmount() {
    this.announcementBloc.popupChannel.unsubscribe();
  }

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  isJSON(str) {
    try {
      return (JSON.parse(str) && !!str);
    } catch (e) {
      return false;
    }
  }
  
  render() {
    return this.state.announcement && (
      <Modal
        toggle={this.toggleModal}
        isOpen={this.state.showModal}
        centered={true}
        size="lg"
      >
        <ModalBody style={{ padding: "0.15rem" }}>
          <div className="close-btn" onClick={this.toggleModal}>
            <Icon icon="icomoon-free:cross" width={20} />
          </div>
          {/* <img */}
          {/*   src="img/announcements/announcement.jpeg" */}
          {/*   alt="latest-ann" */}
          {/*   className="img-fluid" */}
          {/*   style={{ height: "85vh", width: "auto" }} */}
          {/* /> */}
          {/* <div className="text-container">
            <div className="head">
              <span>
                <strong>NOTA DINAS</strong>
              </span>
              <span>No. 176/A.3/B.5/2021</span>
              <span>Tanggal 31 Desember 2021</span>
            </div>

            <p>Kepada Yth.</p>
            <ol style={{ listStyleType: "number", marginBottom: "1rem" }}>
              <li>Pejabat Pimpinan Tinggi Madya</li>
              <li>Pejabat Pimpinan Tinggi Pratama</li>
            </ol>

            <p>
              Sehubungan dengan kegiatan pengembangan Aplikasi Persuratan
              (E-Office), berikut kami sampaikan sebagai berikut:
            </p>
            <ol style={{ listStyleType: "number" }}>
              <li>
                Pengembangan aplikasi persuratan meliputi:
                <ol style={{ listStyleType: "lower-alpha" }}>
                  <li>Terintegrasi dengan Portal E-Office Keminves/BKPM</li>
                  <li>
                    Terintegrasi dengan Data Kepegawaian Tanda Tangan ELektronik
                  </li>
                  <li>Tanda Tangan ELektronik</li>
                  <li>Penomoran secara otomatis</li>
                </ol>
              </li>
              <li>
                Telah dilakukan sosialisasi aplikasi persuratan kepada TU
                Pimpinan dan TU Unit Kerja pada 31 Desember 2021
              </li>
              <li>
                Selanjutnya Aplikasi Persuratan (E-Office) dengan penomoran
                otomatis tersebut dapat diakses melalui desktop dengan alamat
                eoffice.bkpm.go.id dan versi mobile dengan nama E-Office
                Keminves/BKPM (
                <a
                  href="/eOffice_BKPM.apk"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "royalblue" }}
                >
                  download disini
                </a>
                ) menggunakan akun Office 365 mulai tanggal 3 Januari 2021.
              </li>
              <li>
                Untuk informasi lebih lanjut terkait dengan Aplikasi Persuratan
                dapat menghubungi Unit Pusat Pengolahan Data dan Informasi
                (Sdri. Ike Widya Ningrum 081519735080)
              </li>
            </ol>

            <p>
              Demikian, atas perhatian dan kerjasamanya kami sampaikan terima
              kasih.
            </p>
            <p>Sekretaris Menteri/Sekretaris Utama</p>
            <p>Ikmal Lukman</p>
            <hr />
            <p style={{ display: "flex", alignthis.state.announcements: "center" }}>
              Lampiran: <Icon icon="bx:bxs-file-pdf" width={14} color="#F33" />
              <a
                href="/popups_1.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Sosialisasi dan Penerapan Eoffice 2022.pdf
              </a>
            </p>
          </div> */}
          {/* <div className="text-container">
            <p>Yth. Bapak/Ibu.</p>

            <p>Untuk dapat menggunakan aplikasi mobile E-Office versi android, pastikan E-Office versi lama telah ter-uninstall.</p>
            <p>
              <a
                  href="/eOffice_BKPM.apk"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "royalblue" }}
                >
                  Silakan download aplikasi disini
                </a>.
            </p>
            <p>
              Setelah terinstall, buka aplikasi E-Office Keminves/BKPM dan login menggunakan hak akses office 365 (abc@bkpm.go.id).
            </p>
            <p>Untuk sementara aplikasi iOS belum tersedia.</p>
            <p>Terima kasih.</p>
          </div> */}
          {this.isJSON(this.state.announcement.announcement_content) ?
            <div className="text-container content">
              {<div dangerouslySetInnerHTML={{ __html: this.state.announcement.announcement_content_html }} />}
            </div>

            : <div className="text-container content">
              {<div dangerouslySetInnerHTML={{ __html: this.state.announcement.announcement_content != null ? this.state.announcement.announcement_content : this.state.announcement.announcement_title }} />}
        </div>}
        <hr />
                  {this.state.announcement.attachment_uri ? (
                    <a
                      className="announcement-attachment"
                      style={{
                        display: "flex",
                        columnGap: "1rem",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "black",
                        fontWeight: "500",
                        margin: 10
                      }}
                      href={this.state.announcement.attachment_uri}
                    >
                      <Icon icon="bx:bxs-file-pdf" color="#CD1818" width={24} />
                      <span>Lampiran</span>
                    </a>
                  ) : (
                    <div
                      className="no-attachment-container"
                      style={{
                        display: "flex",
                        columnGap: "0.5rem",
                        alignItems: "center",
                        margin: 10
                      }}
                    >
                      <Icon
                        icon="uit:exclamation-triangle"
                        color="#888"
                        width={16}
                      />
                      <span style={{ fontStyle: "italic", color: "#888" }}>
                        Tidak ada lampiran
                      </span>
                    </div>
                  )}
        </ModalBody>
      </Modal>
    );
  }
}

export default AnnouncementPopup;
