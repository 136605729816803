import React from "react";
import { Waypoint } from "react-waypoint";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import "./style.scss";

class HeroSection extends React.Component {
  static contextType = GlobalContext;
  userdata = JSON.parse(localStorage.getItem("userdata"));

  constructor(props) {
    super(props);
    this.state = {
      hero: {
        title: this.createGreeting(),
        name: this.userdata.employee_name,
        nip: this.userdata.employee_nip
      },
    };
  }

  createGreeting() {
    var today = new Date()
    var hour = today.getHours()
    var greeting = ""

    if (hour >= 4 && hour <= 10) {
      greeting = "Selamat Pagi!"
    } else if(hour > 10 && hour <= 14) {
      greeting = "Selamat Siang!"
    } else if(hour > 14 && hour <= 18 ) {
      greeting = "Selamat Sore!"
    } else {
      greeting = "Selamat Malam!"
    }

    return greeting
  }

  hanldeWaypointEnter = () => {
    const { setActiveNav } = this.context;

    setActiveNav("Home");
  };

  render() {
    return (
      <div
        className="hero-section"
        style={{
          paddingBottom: 100,
          borderRadius: "0px 0px 40px 40px",
          background: "linear-gradient(0deg, #DCE7F3 -34.14%, rgba(220, 231, 243, 0.00) 105.34%)"
        }}
        id="intro"
      >
        <div className="container container-hero">
        <div style={{ position: "absolute", right: 0}}>
              <img
                  src="/bg_ornament2.png"
                  alt="wave shape"
                  className="img-fluid"
                  // style={{  width: "25vw", height: "35vh" }}
                />
            </div>
          <div className="row row-hero align-items-center justify-content-between py-5">
            <div className="col-md-5 col-lg-5" style={{ marginTop: 40 }}>
              <div className="hero-content-left" style={{ width: "90%" }}>
                <p style={{ fontWeight: "400", color: "var(--Default-Grey, #575757)", fontSize: "24px", opacity: "43%", margin: 0}} data-aos="fade-up">
                  {this.state.hero.title}
                </p>
                
                <p
                  className="lead"
                  style={{ color: "var(--Default-Grey, #575757)", fontWeight: "600", fontSize: "32px", fontStyle: "normal" }}
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  {this.state.hero.name}
                </p>
                <div style={{ padding: "10px", position: "absolute", height: "41px", borderRadius: "20px", background: "var(--The-Blue, #3E70D1)" } } data-aos="fade-up"
                    data-aos-delay="300" >
                  <p
                    style={{ color: "white", fontWeight: "400", fontSize: "16px", }}
                  >
                    {this.state.hero.nip}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-lg-7">
              <div
                className="hero-animation-img"
                data-aos="fade-up"
                data-aos-delay="250"
                style={{ display: "flex", marginTop: "70px"}}
              >
                <img
                  className="img-fluid d-block animation-one"
                  src="/logo_eoffice.png"
                  style={{ height: 200, margin: 0 }}
                  alt="animation"
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="bottom-img-absolute">
          <img
            src="img/hero-bg-shape-1.svg"
            alt="wave shape"
            className="img-fluid"
          />
        </div> */}
      </div>
    );
  }
}

export default HeroSection;
