import { Component } from "react";
import {
  Col,
  Row,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Modal,
} from "reactstrap";
import {
  AvCheckbox,
  AvCheckboxGroup,
  AvField,
  AvForm,
} from "availity-reactstrap-validation";
import apiResponse from "../../../../../services/apiResponse";
import { ConfirmAlert } from "../../../../../helpers/ui/alert";
import EmployeeSelect from "../EmployeeSelect";

import AgendaBloc from "../../Blocs/AgendaBloc";

import "./style.scss";
import { GlobalContext } from "../../../../../contexts/GlobalContext";

class AgendaModal extends Component {
  static contextType = GlobalContext;
  agendaBloc = new AgendaBloc();
  userRole = parseInt(localStorage.getItem("currentRoleId"));
  userData = JSON.parse(localStorage.getItem("userdata"));

  constructor(props) {
    super(props);
    this.state = {
      dataForm: {
        agenda_type: 1,
        agenda_nip: this.userData.employee_nip,
      },
    };
  }

  handlerOnInputChange = (e) => {
    if (e.currentTarget) {
      this.setState({
        dataForm: {
          ...this.state.dataForm,
          [e.currentTarget.name]: e.currentTarget.value,
        },
      });
    }
  };

  handlerOnSubmit = (e, values) => {
    const { dataForm } = this.context;

    e.preventDefault();

    this.setState({
      dataForm: {
        ...this.state.dataForm,
        ...dataForm,
      },
    });

    this.setState({
      confirm: true,
    });
  };

  submitData = async () => {
    await this.agendaBloc.fetchCreate(this.state.dataForm);
  };

  confirmResponse = (response) => {
    let forState = {};
    switch (response) {
      case "cancel":
        forState = {
          confirm: false,
        };
        break;
      case "confirm":
        forState = {
          loading: true,
          confirm: false,
        };
        this.submitData();
        break;
      case "success":
        forState = {
          success: false,
          redirect: true,
        };
        break;
      case "failed":
        forState = {
          failed: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  componentDidMount() {
    this.agendaBloc.createChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState({
            loading: false,
            success: true,
          });
          setTimeout(() => {
            this.setState({
              success: false,
            });
            this.props.toggleModalAgenda();
            this.props.token();
            this.props.refetchEvents();
          }, 1200);
          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break;
        default:
          break;
      }
    });
  }

  componentWillUnmount() {
    this.agendaBloc.createChannel.unsubscribe();
  }

  renderEmployeeSelect = (internal) => {
    return <EmployeeSelect internal={internal} />;
  };

  renderCardForm = () => {
    return (
      <>
        <Card className="m-0">
          <CardBody>
            <CardTitle>Tambah Agenda</CardTitle>
            <CardSubtitle className="mb-3">
              Tambah agenda baru dengan mengisi form di bawah ini
            </CardSubtitle>
            <hr />
            <AvForm
              className="needs-validation"
              onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
              onKeyPress={this.preventEnter}
            >
              <Row>
                <label
                  htmlFor="agenda_name"
                  className="col-md-3 col-form-label"
                >
                  Nama <span className="text-danger">*</span>
                </label>
                <Col md="9">
                  <AvField
                    name="agenda_name"
                    placeholder="Nama agenda"
                    type="text"
                    errorMessage="Masukkan nama agenda"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    onChange={this.handlerOnInputChange}
                    id="agenda_name"
                  />
                </Col>
              </Row>
              <Row>
                <label
                  htmlFor="agenda_start"
                  className="col-md-3 col-form-label"
                >
                  Mulai <span className="text-danger">*</span>
                </label>
                <Col md="4">
                  <AvField
                    name="agenda_start"
                    type="datetime-local"
                    errorMessage="Masukkan waktu mulai"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="agenda_start"
                    onChange={this.handlerOnInputChange}
                  />
                </Col>
                <label htmlFor="agenda_end" className="col-md-1 col-form-label">
                  Selesai
                </label>
                <Col md="4">
                  <AvField
                    name="agenda_end"
                    type="datetime-local"
                    errorMessage="Masukkan waktu selesai"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="agenda_end"
                    onChange={this.handlerOnInputChange}
                  />
                </Col>
              </Row>
              <Row>
                <label
                  htmlFor="agenda_description"
                  className="col-md-3 col-form-label"
                >
                  Keterangan
                </label>
                <Col md="9">
                  <AvField
                    name="agenda_description"
                    placeholder="Keterangan agenda..."
                    type="textarea"
                    errorMessage="Masukkan Keterangan"
                    className="form-control"
                    id="agenda_description"
                    onChange={this.handlerOnInputChange}
                  />
                </Col>
              </Row>
              <Row>
                <label
                  htmlFor="agendy_type"
                  className="col-md-3 col-form-label"
                >
                  Jenis Agenda
                </label>
                <Col md="9">
                  <AvField
                    name="agenda_type"
                    type="select"
                    errorMessage="Jenis agenda tidak boleh kosong"
                    className="form-control"
                    id="agenda_type"
                    onChange={this.handlerOnInputChange}
                    style={{ maxHeight: "2.5rem" }}
                  >
                    <option value={0}>Offline</option>
                    <option value={1}>Online</option>
                  </AvField>
                </Col>
              </Row>
              <Row>
                <label
                  htmlFor="agenda_location"
                  className="col-md-3 col-form-label"
                >
                  Lokasi / URL <span className="text-danger">*</span>
                </label>
                <Col md="9">
                  <AvField
                    name="agenda_location"
                    placeholder="Lokasi / URL agenda"
                    type="text"
                    errorMessage="Masukkan lokasi agenda"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="agenda_location"
                    onChange={this.handlerOnInputChange}
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <label
                  htmlFor="agenda_participants"
                  className="col-md-3 col-form-label"
                >
                  Partisipan (Internal)
                </label>
                <Col md="9">{this.renderEmployeeSelect(true)}</Col>
              </Row>

              <Row>
                <label
                  htmlFor="agenda_participants_ext"
                  className="col-md-3 col-form-label"
                >
                  Partisipan (External)
                </label>
                <Col md="9">{this.renderEmployeeSelect(false)}</Col>
              </Row>

              <Row className="mt-3">
                <Col md="3"></Col>
                <Col md="9">
                  <Button
                    color="success"
                    onClick={this.props.toggleModalAgenda}
                  >
                    <i className="uil-arrow-left"></i> Kembali
                  </Button>{" "}
                  <Button color="danger" type="submit">
                    <i className="uil-save"></i> Simpan
                  </Button>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>
        <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan menyimpan data ini?"
          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"
          successTitle="Berhasil!"
          successInfo="Data berhasil disimpan"
          failedTitle="Gagal!"
          failedInfo="Data gagal disimpan"
          showConfirm={this.state.confirm}
          showLoading={this.state.loading}
          showSuccess={this.state.success}
          showFailed={this.state.failed}
          response={this.confirmResponse}
        />
      </>
    );
  };

  render() {
    return (
      <Modal
        centered
        size="lg"
        toggle={this.props.toggleModalAgenda}
        isOpen={this.props.showModalAgenda}
        backdrop="static"
      >
        {this.props.showModalAgenda ? this.renderCardForm() : null}
      </Modal>
    );
  }
}

export default AgendaModal;
