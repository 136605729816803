import { Icon } from "@iconify/react";
import { Link } from "react-router-dom"
import moment from "moment";
import {
  AvCheckbox,
  AvCheckboxGroup,
  AvField,
  AvForm
} from "availity-reactstrap-validation";
import React, { Component } from "react";
import { ConfirmAlert } from "../../../../../helpers/ui/alert";
import {
  FileInput,
  generateFormData
} from "../../../../../helpers/ui/FileInput";
import { cancelToken } from "../../../../../services/adapters/base";
import apiResponse from "../../../../../services/apiResponse";
import TicketBloc from "../../Blocs/TicketBloc";
import ReactTimeAgo from "react-time-ago";
import CustomSelect from "../../../../../helpers/ui/CustomSelect";
import "./style.scss";
import { Modal, ModalBody } from "reactstrap";
import PanduanSection from "../PanduanSection";
import {
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Media,
  Row,
  Form,
  UncontrolledDropdown,
  Button
} from "reactstrap"
//SimpleBar
import SimpleBar from "simplebar-react"
import avatarMale from "../../../../../assets/images/avatar_male.png";
//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import { createChat, getMessages, currentUser, getListUser, sendMessage, getListChat, getChat, markChatAsRead } from "../../../../../services/teamsService";
import Shimmer from 'react-shimmer-effect'

let reformatTypeHelpdeskService = function (data) {
  return data.map(function (service) {
    let newObj = {};
    newObj["label"] = service.helpdesk_service_name;
    newObj["value"] = service.helpdesk_service_id;
    return newObj;
  });
}

let reformatTypeHelpdeskModule = function (data) {
  return data.map(function (module) {
    let newObj = {};
    newObj["label"] = module.helpdesk_module_name;
    newObj["value"] = module.helpdesk_module_id;
    return newObj;
  });
}

class HelpDesk extends Component {
  source;
  ticketBloc = new TicketBloc();
  user = JSON.parse(localStorage.getItem("userdata"));

  constructor(props) {
    super(props);
    this.messageContainerRef = React.createRef();
    this.state = {
      dataForm: {
        ticket_service: 0,
      },
      services: this.props.services,
      optServices: reformatTypeHelpdeskService(this.props.services),
      showHelpdesk: false,
      showGuide: false,
      showChatBox: false,
      showAddUser: false,
      chatId: null,
      currentChat: null,
      currentMessage: null,
      messages: [],
      allChat: []
    };
    this.delayDebounceFn = null;
  }

  handleTicketServiceChange = (e) => {
    this.setState({
      dataForm: {
        ...this.state.dataForm,
        ["helpdesk_service_id"]: e.value,
      },
      optModules: reformatTypeHelpdeskModule(this.state.services.filter((helpdeskService) => helpdeskService.helpdesk_service_id == e.value)[0].modules)
    });
  };

  handleTicketModuleChange = (e) => {
    this.setState({
      dataForm: {
        ...this.state.dataForm,
        ["helpdesk_module_id"]: e.value,        
      },
    });
  };

  handleInputChange = (e) => {
    if (e.currentTarget.name == "ticket_service") {
      this.setState({
        optModules: reformatTypeHelpdeskModule(this.state.services.filter((helpdeskService) => helpdeskService.helpdesk_service_id == e.currentTarget.value)[0].modules)
      })
    }

    try {
      this.setState({
        dataForm: {
          ...this.state.dataForm,
          [e.currentTarget.name]: e.currentTarget.value,
        },
      });
    } catch (e) {
      this.setState({
        ...this.state.dataForm,
        ticket_attachment: e.target.value,
      });
    }

  };

  handleChatClick = () => {
    this.setState({
      showHelpdesk: !this.state.showHelpdesk,
    });
  };

  handleGuideClick = () => {
    this.setState( {
      showGuide: !this.state.showGuide
    })
  }

  handleAddUserClick = () => {
    this.setState({
      showAddUser: !this.state.showAddUser,
    });
    
    if(this.state.showAddUser == true) {
      this.fetchUsers()
    }
  };

  handleChatBoxClick = () => {
    this.fetchListChat();
    this.setState( {
      showChatBox: !this.state.showChatBox
    })
  }

  submitData = async () => {
    this.state.dataForm["employee_uuid"] =  this.user.employee_uuid;
      
    const formData = generateFormData(this.state.dataForm, {
      field: "ticket_attachment",
      name: "ticketAttachments/attachment",
    });
    await this.ticketBloc.fetchCreate(formData);
  };

  handlerOnSubmit = (e, values) => {
    e.preventDefault();

    this.setState({
      confirm: true,
      dataForm: {
        ...this.state.dataForm,
        ...values,
      },
    });
  };

  preventEnter = (e) => {
    if (e.which === 13) {
      e.preventDefault();
    }
  };

  confirmResponse = (response) => {
    let forState = {};
    switch (response) {
      case "cancel":
        forState = {
          confirm: false,
        };
        break;
      case "confirm":
        forState = {
          loading: true,
          confirm: false,
        };
        this.submitData();
        break;
      case "success":
        forState = {
          success: false,
          redirect: true,
        };
        break;
      case "failed":
        forState = {
          failed: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  setTokenAPI = () => {
    if (typeof this.source != typeof undefined) {
      this.source.cancel();
    }
    this.source = cancelToken();
    this.loadData();
  };

  async componentDidMount() {
    const initName = this.user.employee_name;
    const initEmail = this.user.employee_email_internal;

    this.ticketBloc.createChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState({
            loading: false,
            success: true,
          });
          setTimeout(() => {
            this.setState({
              showHelpdesk: false,
              loading: null,
              success: null,
            });
          }, 1200);
          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break;
        default:
          break;
      }
    });

    if (initName && initEmail) {
      this.setState({
        dataForm: {
          ...this.state.dataForm,
          ticket_init_name: initName,
          ticket_init_email: initEmail,
        },
      });
    }

    this.fetchUsers();
    this.intervalId = setInterval(() => {
      this.fetchMessages()
      this.fetchListChat()
    }, 5000);
  }

  fetchMessages = async (chat = null, scrollToBottom = false) => {
    if (chat != null || this.state.currentChat != null && this.state.showChatBox == true) {  
      var chatData = chat != null ? chat : this.state.currentChat
      if (chatData) {
        this.setState({
          chatId: chatData?.id
        })
        getMessages(chatData.id).then((messages) => {
          if (messages != this.state.messages) {
            this.setState({
              messages: messages.value.reverse()
            })  
          }
  
          if (scrollToBottom) {
            this.scrollToBottom();
            markChatAsRead(chatData?.id)
            this.fetchListChat()
          }
        })
        
      }
    }
  }

  fetchListChat = async () => {
    if(this.state.showChatBox == true) {
      var chats = await getListChat()
      this.setState({
        chats: chats.value,
        allChat: chats.value
      })
    }
  }

  createNewChat = async (userId) => {
    var newChat = await createChat(userId)
    if (newChat != null) {
      var chat = await getChat(newChat.id)
      this.setState({
        currentChat: chat
      })
      this.fetchMessages(chat, true)
      this.handleAddUserClick() 
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
    this.ticketBloc.createChannel.unsubscribe();
    clearTimeout(this.delayDebounceFn);
  }

  sendMessage = async () => {
    if(this.state.chatId != null) {
      await sendMessage(this.state.chatId, this.state.currentMessage)
      this.setState({
        currentMessage: ""
      })
      this.fetchMessages(null, true)
      this.fetchListChat();
    }
  }

  scrollToBottom = () => {
    try {
      if (this.messageContainerRef) {

          const container = this.messageContainerRef;
          container.scrollTop = container.scrollHeight;  
      
        
      }  
    } catch (error) {}
  };

  searchUser = async (search) => {
    if (search != null || search != "") {
      const searchedData = this.state.allChat.filter(chat => {
        return chat.members.some(member => member.displayName.toLowerCase().includes(search.toLowerCase()));
      });
  
      this.setState({
        chats: searchedData
      })
    } else {
      this.setState({
        chats: this.state.allChat
      })
    }
  }

  handleSearchUserChange = (e) => {
    const search = e.target.value;
    clearTimeout(this.delayDebounceFn);

    this.delayDebounceFn = setTimeout(() => {
      this.fetchUsers(search)
    }, 1500);
  };

  fetchUsers = (search = null) => {
    getListUser(search).then((result) => {
      this.setState({
        addUsers: result.value
      })
    });
    
  }

  chatBox() {
    return <div className="d-lg-flex">
    <Card className="chat-leftsidebar">
        <div className="p-3">
            <div className="search-box chat-search-box">
                <div className="position-relative">
                    <Input
                        type="text"
                        className="form-control bg-light border-light rounded"
                        placeholder="Search..."
                        onChange={(event) => {
                          this.searchUser(event.target.value);
                        }}
                    />
                    <i className="uil uil-search search-icon"></i>
                </div>
            </div>
        </div>
        <div className="pb-3">
            <SimpleBar
                style={{ height: "470px" }}
                data-simplebar
            >
                <div className="p-4 border-top" >
                    <div>
                        <div className="float-end">
                            <Link to="#" onClick={() => {this.handleAddUserClick()}} className="text-primary"><i className="mdi mdi-plus"></i> New Chat</Link>
                        </div>
                        <h5 className="font-size-16 mb-3"><i className="uil uil-users-alt me-1"></i> Users</h5>
                        <ul className="list-unstyled chat-list">
                            {this.state.chats ?
                                this.state.chats.map(chat => (
                                  <li key={chat.id} className={chat.id == this.state.chatId ? "active" : ""}>
                                    <Link
                                        to="#"
                                        onClick={() => {
                                          this.setState({
                                            currentChat: chat
                                          })
                                          this.fetchMessages(chat, true)
                                        }}
                                    >
                                        <Media className="d-flex">
                                            <div className="avatar-xs me-3">
                                              <img
                                                  style={{width: "32px", height: "32px" }}
                                                  className="rounded-circle header-profile-user"
                                                  src={avatarMale}
                                                  alt=""
                                              />  
                                            </div>

                                            <Media className="flex-1 overflow-hidden" body>
                                                <h5 className="text-truncate font-size-14 mb-1">
                                                  {chat.members.filter((member) => currentUser.profile.oid != member.userId)[0]?.displayName}
                                                </h5>
                                                <p className="mb-0" style={{
                                                  width: "100%",
                                                  overflow: 'hidden',
                                                  textOverflow: 'ellipsis',
                                                  display: '-webkit-box',
                                                  WebkitLineClamp: 1,
                                                  WebkitBoxOrient: 'vertical',
                                                  fontWeight: (moment(chat?.lastMessagePreview?.createdDateTime) > (moment(chat?.viewpoint?.lastMessageReadDateTime)) ? "bold" : "normal")
                                                }} dangerouslySetInnerHTML={{ __html: chat?.lastMessagePreview?.body?.content }}>
                                                </p>
                                            </Media>
                                            <div className="font-size-11">
                                              {chat?.lastMessagePreview != null && chat?.lastMessagePreview?.body?.content != "<systemEventMessage/>" && (
                                                <ReactTimeAgo
                                                  date={new Date(chat?.lastMessagePreview?.createdDateTime)}
                                                  locale="id-ID"
                                                  timeStyle="twitter"
                                                  style={{ fontWeight: (moment(chat?.lastMessagePreview?.createdDateTime) > (moment(chat?.viewpoint?.lastMessageReadDateTime)) ? "bold" : "normal") }}
                                                />
                                              )}
                                            </div>
                                        </Media>
                                    </Link>
                                  </li>
                                )) :  
                               
                                [1,2,3,4,5,6].map((id) => (
                                  <li key={id} style={{ marginBottom: "5px" }}>
                                      <hr/>
                                      <Media className="d-flex">
                                        <div className="avatar-xs me-3">
                                            <Shimmer>
                                            <div
                                              className="shimmer"
                                              style={{ width: "32px", height: "32px" }}
                                            ></div>
                                          </Shimmer>
                                        </div>

                                        <Media className="flex-1 overflow-hidden" body>
                                            <h5 className="text-truncate font-size-14 mb-1">
                                              <Shimmer>
                                                <div
                                                  className="shimmer"
                                                  style={{ width: "95%", height: 15 }}
                                                ></div>
                                              </Shimmer>
                                            </h5>
                                            <Shimmer>
                                              <div
                                                className="shimmer"
                                                style={{ width: "100%", height: 15 }}
                                              ></div>
                                            </Shimmer>
                                        </Media>
                                        <div className="font-size-11">
                                          <Shimmer>
                                            <div
                                              className="shimmer"
                                              style={{ width: "40px", height: 15 }}
                                            ></div>
                                          </Shimmer>
                                        </div>
                                      </Media>
                                  </li>
                                ))
                               
                               }
                        </ul>
                    </div>
                </div>
            </SimpleBar>
        </div>
    </Card>

    <div className="w-100 user-chat mt-4 mt-sm-0 ms-lg-1">
        <Card>
          <div className="p-3 px-lg-4 border-bottom">
            <Row>
                <Col md="12" className="col-6" style={{ height: "20px" }}>
                    <h5 className="font-size-16 mb-1 text-truncate">{this.state.currentChat != null ? this.state.currentChat.members[1].displayName : null}</h5>
                </Col>
            </Row>
        </div>
            <div className="px-lg-2">
                <div className="chat-conversation p-3">
                    <PerfectScrollbar style={{ height: "410px" }} className="list-unstyled mb-0"  containerRef={(ref) => (this.messageContainerRef = ref)}  >
                        
                        {this.state.messages &&
                            this.state.messages.map((message) => {
                              if (message.from != null) {
                                return (
                                    <li
                                      key={"test_k" + message.id}
                                      className={
                                          message.from.user.id === currentUser.profile.oid
                                              ? "right"
                                              : ""
                                      }
                                    >
                                    <div className="conversation-list">
                                        <div className="ctext-wrap">
                                            <div className="ctext-wrap-content">
                                                <h5 className="font-size-14 conversation-name">
                                                    <a className="text-dark">{message.from.user.displayName}</a>
                                                    <span className="d-inline-block font-size-12 text-muted ms-2">{moment(message.createdDateTime).format(
                                                        "LL HH:mm "
                                                    )}</span>

                                                </h5>
                                                <p className="mb-0" dangerouslySetInnerHTML={{ __html: message.body.content }}></p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                              )
                              }
                                 
                        })}
                    </PerfectScrollbar>
                </div>
            </div>
            <div className="p-3 chat-input-section">
                <Row>
                    <div className="col">
                        <div className="position-relative">
                            <input  onKeyPress={event => {
                            if (event.key === 'Enter') {
                              this.sendMessage()
                            }
                          }} type="text" className="form-control chat-input rounded" placeholder="Enter Message..." value={this.state.currentMessage} onChange={ (event) => {this.setState({currentMessage:event.target.value})}}/>
                            
                        </div>
                    </div>
                    <div className="col-auto">
                        <Button onClick={() => {this.sendMessage()}} type="submit" color="primary" className="chat-send w-md waves-effect waves-light" disabled={this.state.chatId == null || this.state.currentMessage == null}><span className="d-none d-sm-inline-block me-2">Send</span> <i className="mdi mdi-send float-end"></i></Button>
                    </div>
                </Row>
            </div>
        </Card>
    </div>
    </div>
  }

  addUser() {
    return <Card className="chat-leftsidebar">
    <div className="p-3">
        <div className="search-box chat-search-box">
            <div className="position-relative">
                <Input
                    type="text"
                    className="form-control bg-light border-light rounded"
                    placeholder="Search..."
                    onChange={(event) => {
                      this.handleSearchUserChange(event)
                    }}
                />
                <i className="uil uil-search search-icon"></i>
            </div>
        </div>
    </div>
    <div className="pb-3">
        <SimpleBar
            style={{ height: "470px" }}
            data-simplebar
        >
            <div className="p-4 border-top" >
                <div>
                    <h5 className="font-size-16 mb-3"><i className="uil uil-users-alt me-1"></i> Users</h5>
                    <ul className="list-unstyled chat-list group-list">
                        {this.state.addUsers &&
                            this.state.addUsers.map(user => (
                                <li key={user.id}>
                                    <Link
                                        to="#"  
                                        onClick={() => {
                                          this.createNewChat(user.id)
                                        }}
                                    >
                                        <Media className="d-flex align-items-center mb-2">
                                            <div className="avatar-xs me-3">
                                              <img
                                                style={{width: "32px", height: "32px" }}
                                                className="rounded-circle header-profile-user"
                                                src={avatarMale}
                                                alt=""
                                              />  
                                            </div>
                                            <Media body className="flex-1">
                                              <h5 className="font-size-14 mb-0" style={{ width: "90%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                {user.displayName}
                                              </h5>  
                                            </Media>
                                        </Media>
                                    </Link>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
        </SimpleBar>
    </div>
  </Card>
  }

  render() {
    return (
      <div>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px"}} >
          <div className="float-container-chat">
            <div className="float-btn-chat" onClick={this.handleChatBoxClick}>
                <Icon
                  icon="bx-chat"
                  className={this.state.showChatBox ? "inactive" : "active"}
                />
                <Icon
                  icon="line-md:close"
                  className={this.state.showChatBox ? "active" : "inactive"}
                />
            </div>
          </div>
          <div className="float-container-guide">
            <div className="float-btn-guide" onClick={this.handleGuideClick}>
                <Icon
                  icon="ri-question-line"
                  className={this.state.showGuide ? "inactive" : "active"}
                />
                <Icon
                  icon="line-md:close"
                  className={this.state.showGuide ? "active" : "inactive"}
                />
            </div>
          </div>
          <div className="float-container-helpdesk">
            <div className="float-btn" onClick={this.handleChatClick}>
              <Icon
                icon="ri:customer-service-2-line"
                className={this.state.showHelpdesk ? "inactive" : "active"}
              />
              <Icon
                icon="line-md:close"
                className={this.state.showHelpdesk ? "active" : "inactive"}
              />
            </div>
            <a className="float-btn2" target="_blank" href={`https://eoffice${process.env.REACT_APP_ENVIRONMENT === "development" ? "-dev" :  ""}.bkpm.go.id/admin/helpdesk.html`}>
            <Icon
              icon="ri:find-replace-fill"
              className="active"
            />
          </a>
          </div>
          {/* <a className="float-btn2" target="_blank" href={`http://localhost:3001/admin/helpdesk.html`}> */}
        </div>
        {this.state.showHelpdesk && (
          <div className="float-form-container" style={{ zIndex: 99 }}>
            <h5 className="form-title">Helpdesk</h5>
            <p className="form-subtitle">
              Silahkan submit keluhan anda melalui isian di bawah ini.
            </p>
            <hr />
            <AvForm
              className="needs-validation"
              onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
              onKeyPress={this.preventEnter}
            >
              <div className="form-group">
                <label htmlFor="ticket_subject">Subjek</label>
                <AvField
                  name="ticket_subject"
                  placeholder="Masukkan subjek..."
                  type="text"
                  errorMessage="Subjek tidak boleh kosong"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="ticket_subject"
                  onChange={this.handleInputChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="ticket_service">Layanan</label>
                <CustomSelect
                  name="ticket_service"
                  id="ticket_service"
                  errorMessage="Silahkan pilih layanan"
                  options={this.state.optServices}
                  validate={{ required: { value: true } }}
                  onChange={this.handleTicketServiceChange}
                  placeholder="Pilih layanan" />
              </div>
 
              <div className="form-group">
                <label htmlFor="ticket_service">Modul</label>
                <CustomSelect
                  name="ticket_module"
                  id="ticket_module"
                  errorMessage="Silahkan pilih modul"
                  options={this.state.optModules}
                  validate={{ required: { value: true } }}
                  onChange={this.handleTicketModuleChange}
                  placeholder={this.state.optModules == null ? "Silahkan pilih layanan terlebih dahulu" : "Pilih modul" } />
              </div>

              <div className="form-group">
                <label htmlFor="ticket_content">Deskripsi</label>
                <AvField
                  name="ticket_content"
                  placeholder="Masukkan dreskripsi keluhan..."
                  type="textarea"
                  rows={3}
                  errorMessage="Deskripsi tidak boleh kosong"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="ticket_content"
                  onChange={this.handleInputChange}
                />
              </div>

              <label htmlFor="ticket_attachment" className="col-form-label">
                Lampiran
              </label>
              <div className="form-group">
                <FileInput
                  name="ticket_attachment"
                  accept=".pdf,.doc,.docx,.xls,.xlsx,.txt,.jpg,.png,.jpeg"
                  isMultiple={true}
                  maxFiles={3}
                />
                <p style={{ fontWeight: 500 }} className="text-secondary">
                  Format file yang diijinkan: .pdf, .doc, .docx, .xls, .xlsx,
                  .txt, .jpg, .png
                </p>
                <p className="text-secondary">
                  *Maksimal 3 files dengan ukuran paling besar 10MB.
                </p>
              </div>
              <div className="form-group">
                <AvCheckboxGroup
                  name="checkboxExample"
                  required
                  errorMessage="Agreement harus di centang"
                >
                  <AvCheckbox
                    label="Saya yakin dan bertanggung jawab atas data ini"
                    value="1"
                  />
                </AvCheckboxGroup>
              </div>
              <div className="form-group pb-4" style={{ fontWeight: 500 }}>
                <button
                  className="btn btn-primary float-right px-3"
                  type="submit"
                >
                  <Icon
                    icon="icon-park-outline:send-email"
                    className="mr-2"
                    width={15}
                  />
                  <span>Kirim</span>
                </button>
                <button
                  className="btn btn-danger float-right px-3 mr-3"
                  onClick={() => {
                    this.setState({
                      showHelpdesk: false,
                    });
                  }}
                >
                  <Icon
                    icon="icon-park-outline:close"
                    className="mr-2"
                    width={15}
                  />
                  <span>Batal</span>
                </button>
              </div>
            </AvForm>

            <ConfirmAlert
              confirmTitle="Konfirmasi!"
              confirmInfo="Apakah anda yakin akan mengirim data ini?"
              loadingTitle="Mengirim data..."
              loadingInfo="Tunggu beberapa saat"
              successTitle="Berhasil!"
              successInfo="Data berhasil disimpan"
              failedTitle="Gagal!"
              failedInfo="Data gagal disimpan"
              showConfirm={this.state.confirm}
              showLoading={this.state.loading}
              showSuccess={this.state.success}
              showFailed={this.state.failed}
              response={this.confirmResponse}
            />
          </div>
        )}
        <div>
          <Modal isOpen={this.state.showGuide} centered={true} size="xl">
            <ModalBody className="app-modal-body">
              <center><h2
                  style={{ fontWeight: "bold", color: "#0F0465" }}
                >
                  Panduan Aplikasi
                </h2>
                </center>
              <div className="close-btn" onClick={this.handleGuideClick}>
                <Icon icon="icomoon-free:cross" width={20} />
              </div>
              <>
                <PanduanSection />
              </>
            </ModalBody>
          </Modal>
        </div>
        <div>
          <Modal isOpen={this.state.showChatBox} centered={true} size="xl">
            <ModalBody>
              <center><h2
                  style={{ fontWeight: "bold", color: "#0F0465" }}
                >
                  
                </h2>
                </center>
              <div className="close-btn" onClick={this.handleChatBoxClick}>
                <Icon icon="icomoon-free:cross" width={20} />
              </div>
              <>
                {this.chatBox()}
              </>
            </ModalBody>
          </Modal>
        </div>
        <div>
          <Modal isOpen={this.state.showAddUser}  centered={true} size="ls">
            <ModalBody>
              <center><h2
                  style={{ fontWeight: "bold", color: "#0F0465" }}
                >
                </h2>
                </center>
              <div className="close-btn" onClick={this.handleAddUserClick}>
                <Icon icon="icomoon-free:cross" width={20} />
              </div>
              <>
                {this.addUser()}
              </>
            </ModalBody>
          </Modal>
        </div>

      </div>
    );
  }
}

export default HelpDesk;
