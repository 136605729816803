import { apiWrapper, apiCancel } from "../wrapper";

export const initial = "base";
const baseUrl = process.env.REACT_APP_SERVICE_BASE_URL;
const appCode = process.env.REACT_APP_SERVICE_BASE_APPCODE;
const outputType = "json";

export const authConnect = "auth/connect/";
export const notificationCheck = "notification/check/";
export const authGetAccessRole = "auth/getAccessRole/";
export const authGetAccessMenus = "auth/getAccessMenus/";

export const userGetData = 'user/getData/'

export const accessCreate = "access/create/";
export const menuList = "menu/list/";

//dashboard->notification
export const notificationList = "pages/notification/list/";
export const notificationFlagRead = "pages/notification/flag/";

//agenda
export const agendaList = "agenda/getAgendas/";
export const agendaWeekList = "agenda/getWeekAgendas/";
export const agendaDetail = "agenda/getAgenda/";
export const agendaCreate = "agenda/createAgenda/";
export const agendaUpdate = "agenda/updateAgenda/";
export const agendaActive = "agenda/activeAgenda/";
export const agendaInactive = "agenda/inactiveAgenda/";
export const agendaNotulensi = "agenda/notulensiAgenda/";

//ticket
export const ticketList = "ticket/getTickets/";
export const ticketDetail = "ticket/getTicket/";
export const ticketCreate = "ticket/createTicket/";
export const ticketUpdate = "ticket/updateTicket/";
export const ticketClose = "ticket/closeTicket/";
export const ticketReopen = "ticket/reopenTicket/";
export const ticketReply = "ticket/replyTicket/";
export const ticketReplies = "ticket/getTicketReplies/";
export const ticketEscalation = "ticket/escalationTicket/";
export const ticketBackToPic = "ticket/backToPic/";
export const ticketExport = "ticket/exportExcel/";
export const ticketFetchPriority = "ticket/fetchPriority/";

//announcement
export const announcementList = "announcement/getAnnouncements/";
export const announcementPopup = "announcement/getPopupAnnouncement/";
export const announcementDetail = "announcement/getAnnouncement/";
export const announcementCreate = "announcement/createAnnouncement/";
export const announcementUpdate = "announcement/updateAnnouncement/";
export const announcementActive = "announcement/activeAnnouncement/";
export const announcementInactive = "announcement/inactiveAnnouncement/";

//guide
export const guideList = "guide/getGuides/";
export const guideDetail = "guide/getGuide/";
export const guideCreate = "guide/createGuide/";
export const guideUpdate = "guide/updateGuide/";

//guide category
export const guideCategoryList = "guide/getGuideCategories/";
export const guideCategoryDetail = "guide/getGuideCategory/";
export const guideCategoryCreate = "guide/createGuideCategory/";
export const guideCategoryUpdate = "guide/updateGuideCategory/";

//Portal Service
export const portalServiceList = "portalService/getPortalServices/";

//Helpdesk Service
export const helpdeskServiceList = "helpdesk/getHelpdeskServices/";

//employee
export const searchEmployee = "user/searchUser/";
export const finishTour = "user/finishTour/";

//dashboard->activity
export const activityList = "pages/activity/list/";

//attendance service
export const attendanceExt = "meetingRegistration/attendanceExt/";
export const attendanceInt = "meetingRegistration/attendanceInt/";

//keyword
export const keywordList = "keyword/getKeywords/";
export const keywordCreate = "keyword/createKeyword/";

//rough
export const roughList = "rough/getRoughs/";

///////////////////////////////////////////////////////////////////////////////////////
//Meeting Registration
export const meetingRegistrationList = "meetingRegistration/getMeetingRegistrations/";
export const meetingRegistrationDetail = "meetingRegistration/getMeetingRegistration/";
export const meetingRegistrationCreate = "meetingRegistration/createMeetingRegistration/";
export const meetingRegistrationUpdate = "meetingRegistration/updateMeetingRegistration/";
export const meetingRegistrationDelete = "meetingRegistration/deleteMeetingRegistration/";
export const meetingRegistrationCancel = "meetingRegistration/cancelMeetingRegistration/";


///////////////////////////////////////////////////////////////////////////////////////
//Peminjamam ruang rapat
export const buildingList = "prosespeminjaman/ruangrapat/listmastermbuilding/";
export const floorList = "prosespeminjaman/ruangrapat/listmastermfloor/";
export const roomList = "prosespeminjaman/ruangrapat/listmastermroom/";

//Verify Pdf
export const verifyPdf = "eseal/verifyPdf/";

//export const cancelToken = () => apiCancel
export const cancelToken = () => {
  return apiCancel();
};
export const apiRequest = async (
  method,
  route,
  data = { params: null, body: null, cancelToken: null },
  needToken = true
) => {
  if (needToken) {
    const thisTime = Math.floor(Date.now() / 1000);
    const expire = localStorage.getItem(initial + "accessExpired");
    if (expire < thisTime) {
      console.log(route)
      await apiWrapper(
        baseUrl,
        initial,
        appCode,
        outputType,
        "post",
        "auth/getAccessToken/",
        data
      )
        .then((result) => {
          localStorage.setItem(
            initial + "accessToken",
            result.response.access_token
          );
          return apiWrapper(
            baseUrl,
            initial,
            appCode,
            outputType,
            method,
            route,
            data
          );
        })
        .catch((result) => {
          if (route == "meetingRegistration/attendanceExt/") {
            return apiWrapper(
              baseUrl,
              initial,
              appCode,
              outputType,
              method,
              route,
              data
            );
          } else {
            return Promise.reject("Tidak dapat mengambil accessToken");
          }
        });
    } else {
      return apiWrapper(
        baseUrl,
        initial,
        appCode,
        outputType,
        method,
        route,
        data
      );
    }
  }
  return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data);
};
