import moment from 'moment'
import { AvField, AvForm } from "availity-reactstrap-validation";
import classnames from "classnames";
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { Link, Redirect } from "react-router-dom";
import Shimmer from "react-shimmer-effect";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Row,
  Nav,
  Modal,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import apiResponse from "../../../services/apiResponse";
import DetailBloc from "./Blocs/DetailBloc";
import ReplyBloc from "./Blocs/ReplyBloc";
import DataTableBloc from "../Index/Blocs/DataTableBloc";
import PriorityBloc from "./Blocs/PriorityBloc";
import { Icon } from "@iconify/react";
import { ConfirmAlert } from "../../../helpers/ui/alert";
import { Preview } from '../../../helpers/ui/FileInput';
import ReactTimeAgo from "react-time-ago";
import { cancelToken } from "../../../services/adapters/base";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import CloseTicketModal from '../Componenets/CloseTicketModal';
import { generateFormData, LitlePreview, TextFieldWithAttachment } from '../../../helpers/ui/TextFieldWithAttachment';

class IndexView extends Component {
  userdata = JSON.parse(localStorage.getItem("userdata"));
  detailBloc = new DetailBloc();
  replyBloc = new ReplyBloc();
  dataTableBloc = new DataTableBloc();
  priorityBloc = new PriorityBloc();
  source;
  roleId = JSON.parse(localStorage.getItem("currentRoleId"));
  queryParams = new URLSearchParams(this.props.location.search);
  
  constructor(props) {
    super(props);
    this.state = {
      dataForm: {
        ticket_reply_sender_uuid: this.userdata.employee_uuid,
      },
      detail: {},
      trackings: [],
      activeTab: "1",
      showModal: false,
      showCloseTicketModal: false,
      toggleModal: false
    };
  }

  setTokenAPI = () => {
    if (typeof this.source != typeof undefined) {
      this.source.cancel();
    }
    this.source = cancelToken();
  };

  dot = (color = "transparent") => ({
    alignItems: "center",
    display: "flex",

    ":before": {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: "block",
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

  colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    input: (styles) => ({ ...styles, ...this.dot() }),
    placeholder: (styles) => ({ ...styles, ...this.dot("#ccc") }),
    singleValue: (styles, { data }) => {
      const color =
        data.value === 0
          ? "gray"
          : data.value === 1
            ? "gray"
            : data.value === 2
              ? "green"
              : data.value === 3
                ? "yellow"
                : data.value === 4
                  ? "red"
                  : "transparent";
      return { ...styles, ...this.dot(color) };
    },
  };

  handleInputChange = (e) => {
    let val, field;
    if (e.currentTarget) {
      val = e.currentTarget.value;
      field = e.currentTarget.name;
      this.setState({
        dataForm: {
          ...this.state.dataForm,
          [field]: val,
        },
      });
    } else if (e.value && e.label) {
      this.setState({
        dataForm: {
          ...this.state.dataForm,
          ticket_priority: e.value,
        },
      });
      this.submitPriorityChange(e.value);
    }
  };

  submitPriorityChange = (prior) => {
    this.priorityBloc.fetchPriority({
      ...this.state.dataForm,
      ticket_priority: prior,
    });
  };

  handlerOnSubmit = (e, values) => {
    e.preventDefault();

    const queryParams = new URLSearchParams(this.props.location.search);

    let ticket_reply_layer = null;
    if (this.userdata.employee_uuid == this.state.detail.ticket_pic_uuid) {
      ticket_reply_layer = this.state.detail.ticket_layer == 1 ? 1 : 2;
    } else if (this.userdata.employee_uuid == this.state.detail.ticket_pusdatin_pic_uuid) {
      ticket_reply_layer = 2;
    } else {
      ticket_reply_layer = 1;
    }

    this.setState({
      dataForm: {
        ...this.state.dataForm,
        ...values,
        ticket_reply_ticket_uuid: queryParams.get("uuid"),
        ticket_reply_layer: ticket_reply_layer
      },
      confirm: true,
    });
  };

  submitData = async () => {
    const formData = generateFormData(this.state.dataForm, {
      field: "ticket_reply_attachment",
      name: "ticketReplyAttachments/attachment",
    });

    console.log(formData)
    await this.replyBloc.fetchReply(formData);
  };

  handlerOnCloseTicket = () => {
    this.setState({
      confirmClose: true,
    });
  };

  handlerOnEscalationTicket = () => {
    this.setState({
      confirmEscalation: true,
    });
  };

  handlerOnBackToPicTicket = () => {
    this.setState({
      confirmBackToPic: true,
    });
  };

  closeTicket = async () => {
    const queryParams = new URLSearchParams(this.props.location.search);

    await this.dataTableBloc.fetchCloseTicket({
      ticket_uuid: queryParams.get("uuid"),
    });
  };

  escalationTicket = async () => {
    const queryParams = new URLSearchParams(this.props.location.search);

    await this.dataTableBloc.fetchEscalationTicket({
      ticket_uuid: queryParams.get("uuid"),
    });
  };

  backToPicTicket = async () => {
    const queryParams = new URLSearchParams(this.props.location.search);

    await this.dataTableBloc.fetchBackToPicTicket({
      ticket_uuid: queryParams.get("uuid"),
    });
  };

  componentDidMount() {
    this.detailBloc.detailChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          if (result.data.status) {
            let response = result.data.response.result;
            this.setState({
              detail: {
                ...response,
                ticket_priority_color:
                  response.ticket_priority === 0 ||
                    response.ticket_priority === 1
                    ? "secondary"
                    : response.ticket_priority === 2
                      ? "success"
                      : response.ticket_priority === 3
                        ? "warning"
                        : "danger",
                ticket_priority_text:
                  response.ticket_priority === 0
                    ? "No Prior"
                    : response.ticket_priority === 1
                      ? "Low"
                      : response.ticket_priority === 2
                        ? "Medium"
                        : response.ticket_priority === 3
                          ? "High"
                          : "Critical",
              },
              attachments: result.data.response.attachments,
              solved_attachment: result.data.response.solved_attachment,
              trackings: result.data.response.trackings,
              dataForm: {
                ...this.state.dataForm,
                ticket_uuid: response.ticket_uuid,
                ticket_priority: response.ticket_priority,
              },
            });
          }
          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });
    const queryParams = new URLSearchParams(this.props.location.search);

    this.replyBloc.repliesChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          if (result.data.status) {
            let response = result.data.response.result;
            let replies = [];
            if (this.userdata.employee_uuid == this.state.detail.ticket_pic_uuid) {
              replies = response;
            } else if (this.userdata.employee_uuid == this.state.detail.ticket_pusdatin_pic_uuid) {
              replies = response.filter((reply) => reply.ticket_reply_layer == 2);
            } else {
              replies = response.filter((reply) => reply.ticket_reply_layer == 1);
            }

            this.setState({
              ticket_replies: replies,
          });
          }
          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });

    this.replyBloc.replyChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState({
            loading: false,
            success: true,
          });
          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break;
        default:
          break;
      }
    });

    this.dataTableBloc.closeTicketChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState(
            {
              loadingClose: false,
              successClose: true,
              loadingTable: true,
              redirect: true,
            },
            function () {
              this.setTokenAPI();
            }
          );
          break;
        case apiResponse.ERROR:
          this.setState({
            loadingClose: false,
            failedClose: true,
          });
          break;
        default:
          break;
      }
    });

    this.priorityBloc.priorityChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState({
            loading: false,
            success: true,
          });
          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break;
        default:
          break;
      }
    });

    this.dataTableBloc.escalationTicketChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState(
            {
              loadingEscalation: false,
              successEscalation: true,
              loadingTable: true,
              redirect: true,
            },
            function () {
              this.setTokenAPI();
            }
          );
          break;
        case apiResponse.ERROR:
          this.setState({
            loadingEscalation: false,
            failedEscalation: true,
          });
          break;
        default:
          break;
      }
    });

    this.dataTableBloc.backToPicTicketChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState(
            {
              loadingBackToPic: false,
              successBackToPic: true,
              loadingTable: true,
              redirect: true,
            },
            function () {
              this.setTokenAPI();
            }
          );
          break;
        case apiResponse.ERROR:
          this.setState({
            loadingBackToPic: false,
            failedBackToPic: true,
          });
          break;
        default:
          break;
      }
    });

    this.detailBloc.fetchDetail({ uuid: queryParams.get("uuid") });
    this.replyBloc.fetchReplies({ ticket_uuid: queryParams.get("uuid") });
  }

  toggleModal = () => {
    this.setState({
      showCloseTicketModal: !this.state.showCloseTicketModal,
      toggleModal: !this.state.toggleModal
    })  
  };

  successCloseTicket = () => {
    this.setState({
      showCloseTicketModal: !this.state.showCloseTicketModal,
      toggleModal: !this.state.toggleModal
    })  
    this.detailBloc.fetchDetail({ uuid: this.queryParams.get("uuid") });
  };

  componentWillUnmount() {
    this.detailBloc.detailChannel.unsubscribe();
    this.replyBloc.repliesChannel.unsubscribe();
    this.replyBloc.replyChannel.unsubscribe();
    this.dataTableBloc.closeTicketChannel.unsubscribe();
    this.dataTableBloc.backToPicTicketChannel.unsubscribe();
    this.priorityBloc.priorityChannel.unsubscribe();
  }

  confirmResponse = (response) => {
    let forState = {};
    switch (response) {
      case "cancel":
        forState = {
          confirm: false,
        };
        break;
      case "confirm":
        forState = {
          loading: true,
          confirm: false,
        };
        this.submitData();
        break;
      case "success":
        forState = {
          success: false,
          // redirect: true,
        };
        window.location.reload();
        // this.replyBloc.fetchReplies({ ticket_uuid: this.queryParams.get("uuid") });
        break;
      case "failed":
        forState = {
          failed: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  confirmCloseTicket = (response) => {
    let forState = {};
    switch (response) {
      case "cancel":
        forState = {
          confirmClose: false,
        };
        break;
      case "confirm":
        forState = {
          loadingClose: true,
          confirmClose: false,
        };
        this.closeTicket();
        break;
      case "success":
        forState = {
          successClose: false,
          redirect: true,
        };
        break;
      case "failed":
        forState = {
          failedClose: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  togModal = () => {
    this.setState({
      showModal: !this.state.showModal
    })
  }

  confirmEscalationTicket = (response) => {
    let forState = {};
    switch (response) {
      case "cancel":
        forState = {
          confirmEscalation: false,
        };
        break;
      case "confirm":
        forState = {
          loadingEscalation: true,
          confirmEscalation: false,
        };
        this.escalationTicket();
        break;
      case "success":
        forState = {
          successEscalation: false,
          redirect: true,
        };
        break;
      case "failed":
        forState = {
          failedEscalation: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  confirmBackToPicTicket = (response) => {
    let forState = {};
    switch (response) {
      case "cancel":
        forState = {
          confirmBackToPic: false,
        };
        break;
      case "confirm":
        forState = {
          loadingBackToPic: true,
          confirmBackToPic: false,
        };
        this.backToPicTicket();
        break;
      case "success":
        forState = {
          successBackToPic: false,
          redirect: true,
        };
        break;
      case "failed":
        forState = {
          failedBackToPic: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    return (
      <Fragment>
        <Container fluid>
          <Row>
            <Col className="col-12">
              <Card>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab === "1",
                      })}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-home"></i>
                      </span>
                      <span className="d-none d-sm-block">
                        <span>Detail ticket </span>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab === "2",
                      })}
                      onClick={() => {
                        this.toggle("2");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-envelope"></i>
                      </span>
                      <span className="d-none d-sm-block">
                        <span>Tracking ticket </span>
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
          
                <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                  <TabPane tabId="1">
                  
                          <CardBody>
                            <CardTitle>Detail Ticket</CardTitle>
                            <CardSubtitle className="mb-3">
                              Keterangan detil mengenai ticket
                            </CardSubtitle>
                            <hr />
                            {/*<Row className="mb-3">
                              <label className="col-md-3">Ticket Tracking ID</label>
                              <Col md="9" style={{ textTransform: "uppercase" }}>
                                {this.state.detail ? (
                                  <strong>
                                    {this.state.detail.ticket_tracking_id !== ""
                                      ? this.state.detail.ticket_tracking_id
                                      : "-"}
                                  </strong>
                                ) : (
                                  <Shimmer>
                                    <div style={{ width: "80%", height: 15 }}></div>
                                  </Shimmer>
                                )}
                              </Col>
                            </Row>*/}
                            <Row className="mb-3">
                              <label className="col-md-3">Nama Pengadu</label>
                              <Col md="9">
                                {this.state.detail.ticket_init_name ? (
                                  <>
                                    {this.state.detail.ticket_init_name !== ""
                                      ? this.state.detail.ticket_init_name
                                      : "-"}
                                  </>
                                ) : (
                                  <Shimmer>
                                    <div style={{ width: "50%", height: 15 }}></div>
                                  </Shimmer>
                                )}
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <label className="col-md-3">Email Pengadu</label>
                              <Col md="9">
                                {this.state.detail.ticket_init_email ? (
                                  <Link
                                    to="#"
                                    onClick={(e) => {
                                      window.location = `mailto:${this.state.detail.ticket_init_email}`;
                                      e.preventDefault();
                                    }}
                                  >
                                    {this.state.detail.ticket_init_email !== ""
                                      ? this.state.detail.ticket_init_email
                                      : "-"}
                                  </Link>
                                ) : (
                                  <Shimmer>
                                    <div style={{ width: "50%", height: 15 }}></div>
                                  </Shimmer>
                                )}
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <label className="col-md-3">Subjek</label>
                              <Col md="9">
                                {this.state.detail.ticket_subject ? (
                                  <>
                                    {this.state.detail.ticket_subject !== ""
                                      ? this.state.detail.ticket_subject
                                      : "-"}
                                  </>
                                ) : (
                                  <Shimmer>
                                    <div style={{ width: "50%", height: 15 }}></div>
                                  </Shimmer>
                                )}
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <label className="col-md-3">Layanan</label>
                              <Col md="9">
                                {this.state.detail.service_name ? (
                                  <>
                                    {this.state.detail.service_name !== ""
                                      ? this.state.detail.service_name
                                      : "-"}
                                  </>
                                ) : (
                                  <Shimmer>
                                    <div style={{ width: "50%", height: 15 }}></div>
                                  </Shimmer>
                                )}
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <label className="col-md-3">Modul</label>
                              <Col md="9">
                                {this.state.detail.module_name ? (
                                  <>
                                    {this.state.detail.module_name !== ""
                                      ? this.state.detail.module_name
                                      : "-"}
                                  </>
                                ) : (
                                  <Shimmer>
                                    <div style={{ width: "50%", height: 15 }}></div>
                                  </Shimmer>
                                )}
                              </Col>
                            </Row>
                            {this.roleId > 1 && (
                              <Row className="mb-3">
                                <label
                                  htmlFor="ticket_priority"
                                  className="col-md-3 col-form-label"
                                >
                                  Prioritas
                                </label>
                                <Col md="9">
                                  <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable={false}
                                    name="ticket_priority"
                                    options={[
                                      { label: "Pilih prioritas...", value: 0 },
                                      { label: "Low", value: 1 },
                                      { label: "Medium", value: 2 },
                                      { label: "High", value: 3 },
                                      { label: "Critical", value: 4 },
                                    ]}
                                    styles={this.colourStyles}
                                    id="ticket_priority"
                                    onChange={(e) => {
                                      this.handleInputChange(e, true);
                                    }}
                                  />
                                </Col>
                              </Row>
                            )}
                            {/* <Row className="mb-3">
                              <label className="col-md-3">Prioritas</label>
                              <Col md="9" style={{ textTransform: "uppercase" }}>
                                {this.state.detail ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      columnGap: ".5rem",
                                    }}
                                  >
                                    <Icon
                                      icon="gg:overflow"
                                      className={`text-${this.state.detail.ticket_priority_color}`}
                                    />
                                    <span
                                      className={`text-${this.state.detail.ticket_priority_color}`}
                                    >
                                      <strong>
                                        {this.state.detail.ticket_priority !== ""
                                          ? this.state.detail.ticket_priority_text
                                          : "-"}
                                      </strong>
                                    </span>
                                  </div>
                                ) : (
                                  <Shimmer>
                                    <div style={{ width: "20%", height: 15 }}></div>
                                  </Shimmer>
                                )}
                              </Col>
                            </Row> */}
                            <Row className="mb-3">
                              <label className="col-md-3">Pesan</label>
                              <Col md="9">
                                {this.state.detail ? (
                                  <div
                                    className="container rounded py-3"
                                    style={{ backgroundColor: "#DEE9F477" }}
                                  >
                                    <h5>{this.state.detail.ticket_init_name}</h5>
                                    <hr className="my-1" />
                                    <p>
                                      {this.state.detail.ticket_content !== ""
                                        ? this.state.detail.ticket_content
                                        : "-"}
                                    </p>
                                    <p
                                      className="p-0 m-0"
                                      style={{ fontWeight: "500", color: "#999" }}
                                    >
                                      {moment(this.state.detail.created_at).format('LLLL')}
                                      {/* {this.state.detail.created_at ? (
                                        <ReactTimeAgo
                                          date={new Date(this.state.detail.created_at)}
                                          locale="id-ID"
                                        />
                                      ) : null} */}
                                    </p>
                                  </div>
                                ) : (
                                  <Shimmer>
                                    <div style={{ width: "90%", height: 15 }}></div>
                                  </Shimmer>
                                )}
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <label className="col-md-3">Balasan</label>
                              <Col md="9">
                                <div
                                  className="d-flex"
                                  style={{ flexDirection: "column", rowGap: "1rem" }}
                                >
                                  {this.state.ticket_replies ? (
                                    this.state.ticket_replies.length > 0 ? (
                                      this.state.ticket_replies.map((reply, index) => {
                                        return (
                                          <div
                                            className="container rounded py-3"
                                            style={reply.ticket_reply_sender_uuid == this.state.detail.ticket_init_uuid ? { backgroundColor: "#F4E4E977" } : reply.ticket_reply_sender_uuid == this.state.detail.ticket_pic_uuid ? { backgroundColor: "#F47174" } : { backgroundColor: "#ACD1AF" }}
                                            key={index + 1}

                                          >
                                            <h5 style={reply.ticket_reply_sender_uuid == this.state.detail.ticket_init_uuid ? {} : reply.ticket_reply_sender_uuid == this.state.detail.ticket_pic_uuid ? { color: "white" } : { color: "white" }}>{reply.ticket_reply_sender_name}</h5>
                                            <hr className="my-1" />
                                            <p style={reply.ticket_reply_sender_uuid == this.state.detail.ticket_init_uuid ? {} : reply.ticket_reply_sender_uuid == this.state.detail.ticket_pic_uuid ? { color: "white" } : { color: "white" }}>{reply.ticket_reply_content}</p>
                                            
                                              {
                                                reply.attachments ? reply.attachments.length > 0 ?
                                                  <LitlePreview value={reply.attachments} attachmentSource="minio" col={4} /> : null
                                                  : <Shimmer><div style={{ width: 68, height: 15 }}></div></Shimmer>
                                              }
                                            
                                            <p
                                              className="p-0 m-0"
                                              style={{ fontWeight: "500", color: reply.ticket_reply_sender_uuid == this.state.detail.ticket_init_uuid ? "#999" : "white"}}
                                            >
                                              {moment(reply.created_at).format('LLLL')}
                                              {/* <ReactTimeAgo
                                                date={new Date(reply.created_at)}
                                                locale="id-ID"
                                                style={reply.ticket_reply_sender_uuid == this.state.detail.ticket_init_uuid ? {} : reply.ticket_reply_sender_uuid == this.state.detail.ticket_pic_uuid ? { color: "white" } : { color: "white" }}
                                              /> */}
                                            </p>
                                          </div>
                                        );
                                      })
                                    ) : (
                                      <span>
                                        <i>Belum ada balasan.</i>
                                      </span>
                                    )
                                  ) : (
                                    <Shimmer>
                                      <div style={{ width: "90%", height: 15 }}></div>
                                    </Shimmer>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <label className="col-md-3">Lampiran</label>
                              <Col md="9">
                                {
                                  this.state.attachments ?
                                    <Preview value={this.state.attachments} attachmentSource="minio" />
                                    : <Shimmer><div style={{ width: 68, height: 15 }}></div></Shimmer>
                                }
                              </Col>
                            </Row>
                            
                            <Row className="mb-3">
                              <label className="col-md-3">Balas Pesan</label>
                              <Col md="9">
                                {this.state.detail ? (
                                  <>
                                    {this.state.detail.ticket_status !== 4 && (
                                      <>
                                        <AvForm
                                          className="needs-validation"
                                          onValidSubmit={(e, values) =>
                                            this.handlerOnSubmit(e, values)
                                          }
                                          onKeyPress={this.preventEnter}
                                        >
                                          {/* <AvField
                                            name="ticket_reply_content"
                                            placeholder="Balas pesan..."
                                            type="textarea"
                                            errorMessage="Masukkan Keterangan"
                                            className="form-control"
                                            rows={6}
                                            id="ticket_reply_content"
                                            style={{ position: "relative" }}
                                            onChange={this.handleInputChange}
                                          />
                                          <Button
                                            color="primary"
                                            style={{
                                              position: "absolute",
                                              borderRadius: "50%",
                                              right: "1.75rem",
                                              bottom: "1.75rem",
                                              boxShadow: "0 2px 8px rgba(0,0,0,0.2)",
                                            }}
                                            title="Balas Pesan"
                                            type="submit"
                                          >
                                            <i className="uil-comment-redo"></i>
                                          </Button> */}
                                          <TextFieldWithAttachment 
                                            name="ticket_reply_attachment"
                                            accept=".pdf,.jpg,.png,.jpeg"
                                            isMultiple={true}
                                            maxFiles={5}
                                            onTextChanged={this.handleInputChange}
                                          />
                                        </AvForm>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <Shimmer>
                                    <div style={{ width: "68%", height: 15 }}></div>
                                  </Shimmer>
                                )}
                              </Col>
                            </Row>
                            <hr/>
                            {this.state.detail.ticket_status === 4 && (
                              <>
                                <Row className="mb-3">
                                  <label className="col-md-3">Root Cause</label>
                                  <Col md="9">
                                    {this.state.detail ? (
                                      <>
                                        {this.state.detail.ticket_root_cause !== ""
                                          ? this.state.detail.ticket_root_cause
                                          : "-"}
                                      </>
                                    ) : (
                                      <Shimmer>
                                        <div style={{ width: "35%", height: 15 }}></div>
                                      </Shimmer>
                                    )}
                                  </Col>
                                </Row>
                                <Row className="mb-3">
                                  <label className="col-md-3">Action</label>
                                  <Col md="9">
                                    {this.state.detail ? (
                                      <>
                                        {this.state.detail.ticket_action !== ""
                                          ? this.state.detail.ticket_action
                                          : "-"}
                                      </>
                                    ) : (
                                      <Shimmer>
                                        <div style={{ width: "35%", height: 15 }}></div>
                                      </Shimmer>
                                    )}
                                  </Col>
                                </Row>
                                <Row className="mb-3">
                                  <label className="col-md-3">Solved Time</label>
                                  <Col md="9">
                                    {this.state.detail ? (
                                      <>
                                        {this.state.detail.ticket_solved_time !== ""
                                          ? this.state.detail.ticket_solved_time
                                          : "-"}
                                      </>
                                    ) : (
                                      <Shimmer>
                                        <div style={{ width: "35%", height: 15 }}></div>
                                      </Shimmer>
                                    )}
                                  </Col>
                                </Row>
                                <Row className="mb-3">
                                  <label className="col-md-3">Lampiran Solved</label>
                                  <Col md="9">
                                    {
                                      this.state.solved_attachment ?
                                        <Preview value={this.state.solved_attachment} attachmentSource="minio" />
                                        : <Shimmer><div style={{ width: 68, height: 15 }}></div></Shimmer>
                                    }
                                  </Col>
                                </Row>
                              </>
                            )}
                            <Row className="mb-3">
                              <Col md="3"></Col>
                              <Col md="9">
                                <Link to="helpdesk.html">
                                  <Button color="success">
                                    <i className="uil-arrow-left"></i> Kembali
                                  </Button>
                                </Link>{" "}
                                {this.state.detail.ticket_status !== 4 && this.state.detail.ticket_layer == 1 && this.userdata.employee_uuid == this.state.detail.ticket_pic_uuid && (
                                  <Button
                                    color="purple"
                                    type="submit"
                                    title="Tutup Ticket"
                                    onClick={this.toggleModal}
                                  >
                                    <i className="uil-sign-out-alt"></i> Tutup
                                  </Button>
                                )}{" "}
                                {this.state.detail.ticket_status !== 4 && this.state.detail.ticket_layer == 1 && this.userdata.employee_uuid == this.state.detail.ticket_pic_uuid && this.roleId > 1 && (
                                  <Button
                                    color="warning"
                                    type="submit"
                                    title="Eskalasi Ticket"
                                    onClick={this.handlerOnEscalationTicket}
                                  >
                                    <i className="uil-arrow-up"></i> Eskalasi
                                  </Button>
                                )}{" "}
                                {this.state.detail.ticket_status !== 4 && this.state.detail.ticket_layer == 2 && this.userdata.employee_uuid == this.state.detail.ticket_pusdatin_pic_uuid && this.roleId > 1 && (
                                  <Button
                                    color="danger"
                                    type="submit"
                                    title="Eskalasi Ticket"
                                    onClick={this.handlerOnBackToPicTicket}
                                  >
                                    <i className="uil-arrow-down"></i> Kembalikan ke PIC
                                  </Button>
                                )}
                              </Col>
                            </Row>
                          </CardBody>
                        </TabPane>
                        <TabPane tabId="2">
                          <CardBody>
                            <CardTitle>Tracking ticket</CardTitle>
                            <CardSubtitle className="mb-3">
                              Keterangan detil mengenai tracking ticket
                            </CardSubtitle>
                            <hr />
                          </CardBody>
                          <Row>
                            <Col md="12">
                              <VerticalTimeline lineColor="rgb(33, 150, 243)" >
                                {this.state.trackings.map((t, i) => {
                                  const contentStyle = i === 0 ? { background: 'rgb(33, 150, 243)', color: '#000' } :  { background: 'rgba(222, 233, 244, 0.467)', color: '#000' } ;
                                  const arrowStyle = i === 0 ? { borderRight: '7px solid  rgb(33, 150, 243)' } : { borderRight: '7px solid  rgba(222, 233, 244, 0.467)' };
                              
                                  return <VerticalTimelineElement
                                    key={i}
                                    onTimelineElementClick={() => {t.ticket_tracking_desc === "Tiket sedang diproses" ? this.togModal() : void 0}}
                                    className="vertical-timeline-element--work"
                                    contentStyle={contentStyle}
                                    contentArrowStyle={arrowStyle}
                                    date={moment(t.ticket_tracking_date).format('LLL')}
                                    {...{
                                      icon: <Icon icon={t.ticket_tracking_icon}/>,
                                      iconStyle: { background: 'rgb(33, 150, 243)', color: '#fff'}
                                    }}
                                  >
                                    {t.ticket_tracking_desc ? <React.Fragment>                            
                                      <p style={{ margin:0, color: i === 0 ? "#fff" : "#000" }}>{t.ticket_tracking_desc}</p>
                                      <p style={{ color: i === 0 ? '#fff' : '#000', fontSize: "12px", cursor: t.ticket_tracking_desc === "Tiket sedang diproses" ? "pointer" : "none" }}><i>{t.ticket_tracking_desc === "Tiket sedang diproses" ? "Klik untuk melihat detail" : null}</i></p>
                                    </React.Fragment> : undefined}
                                  </VerticalTimelineElement>
                                })}
                              </VerticalTimeline>
                            </Col>
                          </Row>
                        </TabPane>
                    </TabContent>
                  </Card>
                </Col>
              </Row>
            </Container>

            <Modal
              size="lg"
              isOpen={this.state.showModal}
              toggle={() => {
                this.togModal()
              }}
            >
              <div className="modal-header">
                <h5
                  className="modal-title mt-0"
                  id="myLargeModalLabel"
                >
                  Balasan
                  </h5>
                  <div className="close-btn" onClick={() => {
                    this.setState({
                      showModal: false
                    })
                  }}>
                    <Icon icon="icomoon-free:cross" width={20} />
                </div>
              </div>
              <div className="modal-body chat-conversation ">
              {this.state.ticket_replies ? (
                this.state.ticket_replies.length > 0 ? (
                  this.state.ticket_replies.map((reply, index) => {
                    return (
                      <li
                          key={"test_k" + reply.ticket_reply_id}
                          className={
                              reply.ticket_reply_sender_uuid === this.userdata.employee_uuid
                                  ? "right"
                                  : ""
                          }
                          style={{listStyleType: "none"}}
                      >
                          <div className="conversation-list">
                              <div className="ctext-wrap">
                                  <div className="ctext-wrap-content">
                                      <h5 className="font-size-14 conversation-name">
                                          <Link to="#" className="text-dark">{reply.ticket_reply_sender_name}</Link>
                                          <span className="d-inline-block font-size-12 text-muted ms-2">{moment(reply.created_at).format(
                                              "LLLL"
                                          )}</span>

                                      </h5>
                                      <p className="mb-0">{reply.ticket_reply_content}</p>
                                      <div>  
                                          {
                                            reply.attachments ? reply.attachments.length > 0 ?
                                              <LitlePreview value={reply.attachments} attachmentSource="minio" col={reply.attachments.length == 1 ? 12 : reply.attachments.length == 2 ? 6 : 4} justifyContent={reply.ticket_reply_sender_uuid === this.userdata.employee_uuid
                                                ? "end"
                                                : "left"} /> : null
                                              : <Shimmer><div style={{ width: 68, height: 15 }}></div></Shimmer>
                                          }
                                        </div>
                                  </div>

                              </div>
                          </div>
                      </li>
                    );
                  })
                ) : (
                  <span>
                    <i>Belum ada balasan.</i>
                  </span>
                )
              ) : (
                <Shimmer>
                  <div style={{ width: "90%", height: 15 }}></div>
                </Shimmer>
              )}
              </div>
            </Modal>

        <CloseTicketModal
            showModal={this.state.showCloseTicketModal}
            toggleModal={this.successCloseTicket}
            ticketUuid={this.queryParams.get("uuid")}
        />

        <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan menyimpan data ini?"
          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"
          successTitle="Berhasil!"
          successInfo="Data berhasil disimpan"
          failedTitle="Gagal!"
          failedInfo="Data gagal disimpan"
          showConfirm={this.state.confirm}
          showLoading={this.state.loading}
          showSuccess={this.state.success}
          showFailed={this.state.failed}
          response={this.confirmResponse}
        />

        <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan menutup ticket ini?"
          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"
          successTitle="Berhasil!"
          successInfo="Ticket berhasil ditutup"
          failedTitle="Gagal!"
          failedInfo="Data gagal disimpan"
          showConfirm={this.state.confirmClose}
          showLoading={this.state.loadingClose}
          showSuccess={this.state.successClose}
          showFailed={this.state.failedClose}
          response={this.confirmCloseTicket}
        />

        <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan mengeskalasi ticket ini?"
          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"
          successTitle="Berhasil!"
          successInfo="Ticket berhasil dieskalasi"
          failedTitle="Gagal!"
          failedInfo="Data gagal disimpan"
          showConfirm={this.state.confirmEscalation}
          showLoading={this.state.loadingEscalation}
          showSuccess={this.state.successEscalation}
          showFailed={this.state.failedEscalation}
          response={this.confirmEscalationTicket}
        />

        <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan mengembalikan tiket ini ke PIC?"
          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"
          successTitle="Berhasil!"
          successInfo="Tiket berhasil dikembalika ke PIC."
          failedTitle="Gagal!"
          failedInfo="Data tiket gagal dikembalikan ke PIC."
          showConfirm={this.state.confirmBackToPic}
          showLoading={this.state.loadingBackToPic}
          showSuccess={this.state.successBackToPic}
          showFailed={this.state.failedBackToPic}
          response={this.confirmBackToPicTicket}
        />

        {this.state.redirect ? <Redirect to="/admin/helpdesk.html" /> : null}
      </Fragment>
    );
  }
}

//export default IndexView
export default withRouter(IndexView);
